import { configFile } from "@/config";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Button, Flex, Text } from "@chakra-ui/react";

interface Props {
  isPrevDisabled?: boolean;
  page?: number;
  handleNextPage?: any;
  handlePrevPage?: any;
  pageCount?: number;
  isNextDisabled?: boolean;
  isBig?: boolean;
}

export default function Pagination({
  isPrevDisabled,
  page,
  handleNextPage,
  handlePrevPage,
  pageCount,
  isNextDisabled,
  isBig,
}: Props) {

  return (
    <Flex
      width="90%"
      padding="12px"
      bgColor="#fff"
      columnGap={{ base: "16px", md: "32px" }}
      border="1px solid #E2E8F0"
      borderRadius="8px"
      alignItems="center"
      justifyContent="center"
      gap="32px"
      m="0 auto"
    >
      <Button
        aria-label="previous"
        className={isPrevDisabled ? "disabled" : ""}
        onClick={handlePrevPage}
        minWidth={
          !isBig
            ? { base: "90px", md: "150px", lg: "250px" }
            : { base: "90px", lg: "300px" }
        }
        bgColor={configFile.primaryAccentColor}
        height="40px"
        borderRadius="4px"
        lineHeight="28px"
        fontWeight="600"
        fontSize={{ base: "13px", md: "16px" }}
        color="#fff"
        border="1px solid transparent"
        gap="8px"
        _hover={{
          color: "#fff",
          bgColor: `${configFile.secondaryAccentColor}`,
          borderColor: "#E2E8F0",
        }}
        _disabled={{ bgColor: "#fff", borderColor: "#E2E8F0" }}
      >
        <ChevronLeftIcon />
        Anterior
      </Button>
      <Text
        fontSize={{ base: "13px", md: "16px" }}
        color="gray.500"
        fontWeight="400"
        w={isBig ? "100%" : "auto"}
        textAlign="center"
      >
        Página
        {" "}
        {page}
        {" "}
        de
        {" "}
        {pageCount}
      </Text>
      <Button
        aria-label="next"
        className={isNextDisabled ? "disabled" : ""}
        onClick={handleNextPage}
        minWidth={
          !isBig
            ? { base: "90px", md: "150px", lg: "250px" }
            : { base: "90px", lg: "300px" }
        }
        bgColor={configFile.primaryAccentColor}
        height="40px"
        borderRadius="4px"
        lineHeight="28px"
        fontWeight="600"
        fontSize={{ base: "13px", md: "16px" }}
        color="#fff"
        border="1px solid transparent"
        gap="8px"
        _hover={{
          color: "#fff",
          bgColor: `${configFile.secondaryAccentColor}`,
          borderColor: "#E2E8F0",
        }}
        _disabled={{
          color: "gray.800",
          bgColor: "#fff",
          borderColor: "#E2E8F0",
        }}
      >
        Próxima
        <ChevronRightIcon />
      </Button>
    </Flex>
  );
}
