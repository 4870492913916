import React, { useEffect, useState } from "react";
import {
  Box,
  Link as ChakraLink,
  Text,
  Heading,
  Flex,
  Button,
} from "@chakra-ui/react";
import Link from "next/link";
import Image from "next/image";
import { BlogPostsMainData, CategoryData } from "@/Types/types";
import { configFile } from "@/config";

import CategoryListItem from "@/components/CategoryListItem";
import Post from "@/components/MainPage/Post";
import Pagination from "@/components/Pagination";
import BreedPost from "../BreedPage/BreedPost";

interface Props {
  data: BlogPostsMainData[];
  sponsorImg?: string;
  sponsorLink?: string;
  filteredCategories?: Record<"attributes", CategoryData>[];
  isBreedPage?: boolean;
}

export default function RecentPosts({ sponsorImg, sponsorLink, data, filteredCategories, isBreedPage }: Props) {
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>();
  const [isPrevDisabled, setIsPrevDisabled] = useState<boolean>(true);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true);
  const [isPagination, setIsPagination] = useState<boolean>(false);
  const [currentArticles, setCurrentArticles] = useState<BlogPostsMainData[]>([]);
  const [startIndex, setStartIndex] = useState<number>();
  const [lastIndex, setLastIndex] = useState<number>();
  const [showMore, setShowMore] = useState(false);
  const [isMobile, setIsMobile] = useState<Boolean>();

  const LIMIT_PER_PAGE = 10;

  useEffect(() => {
    setIsMobile(window.innerWidth <= 830 ? true : false);
  })

  useEffect(() => {
    setCurrentArticles(data);
  }, [data]);

  useEffect(() => {
    setPageCount(Math.ceil(currentArticles.length / LIMIT_PER_PAGE));

    setIsPagination(Math.ceil(currentArticles.length / LIMIT_PER_PAGE) > 1);
  }, [currentArticles]);

  useEffect(() => {
    setIsPrevDisabled(page === 1);
    setIsNextDisabled(page === pageCount);
    setStartIndex(page * LIMIT_PER_PAGE - LIMIT_PER_PAGE);
    setLastIndex(page * LIMIT_PER_PAGE);
  }, [page]);

  const handleShowMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setShowMore((prevState) => !prevState);
  }

  const handleNextPage = (e: any) => {
    e.preventDefault();
    document
      .getElementById("recent_posts")!
      .scrollIntoView({ behavior: "smooth" });
    setPage(page + 1);
  };

  const handlePrevPage = (e: any) => {
    e.preventDefault();
    document
      .getElementById("recent_posts")!
      .scrollIntoView({ behavior: "smooth" });
    setPage(page - 1);
  };

  return (
    <Box
      as="section"
      width="100%"
      paddingBottom={{ base: "60px", lg: "80px" }}
      paddingTop={{ base: "60px", lg: "80px" }}
      position="relative"
    >
      <Box maxWidth="1216px" margin="0 auto" width="90%" mb="32px" >
        <Heading
          as="h2"
          id="recent_posts"
          fontSize={{base:"24px", lg:"36px"}}
          lineHeight={{base:"32px", lg:"40px"}}
          fontWeight="800"
          color="gray.800"
        >
          Últimas publicações
        </Heading>
      </Box>
      <Flex
        maxWidth="1216px"
        margin="0 auto"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection={{ base: "column", lg: "row" }}
        columnGap="64px"
        width="90%"
      >
        <Flex
          direction="column"
          flexWrap="wrap"
          columnGap="32px"
          rowGap="32px"
          width="100%"
          alignItems="stretch"
          justifyContent="flex-start"
          mb={{ base: "32px", lg: "0" }}
        >
          {currentArticles &&
            currentArticles
              .slice(startIndex, lastIndex)
              .map((item: BlogPostsMainData, index: number) => 
                isBreedPage ? (
                  <BreedPost
                    key={index}
                    post={item}
                    isHorizontal={!isMobile}
                    hasImage
                    hasParagraph
                    hasCategory
                  />
                ) : (
                <Post
                  key={index}
                  post={item}
                  isHorizontal={!isMobile}
                  hasImage
                  hasParagraph
                  hasCategory
                />
              ))}
          {isPagination && (
            <Pagination
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
              isNextDisabled={isNextDisabled}
              isPrevDisabled={isPrevDisabled}
              page={page}
              pageCount={pageCount}
            />
          )}
        </Flex>
        <Flex
          width="100%"
          maxW={{ base: "none", lg: "320px" }}
          columnGap={{ base: "0", md: "32px", lg: "0" }}
          alignItems="space-between"
          rowGap="64px"
          flexDirection={{ base: "column", md: "row", lg: "column" }}
        >
          {filteredCategories && (
            <Box width="100%">
              <Text fontWeight="700" fontSize="18px">
                Principais Categorias
              </Text>
              {filteredCategories
                .slice(0, showMore ? filteredCategories.length : 8)
                .map((item, index) => (
                  <CategoryListItem key={index} item={item.attributes} />
                ))}
              {filteredCategories.length > 8 && (
                <Button
                  aria-label={showMore ? "Show Less" : "Show More"}
                  borderRadius="8px"
                  width="100%"
                  display="flex"
                  overflow="hidden"
                  alignSelf="stretch"
                  boxShadow="base"
                  mt="12px"
                  bgColor={configFile.primaryAccentColor}
                  color="#fff"
                  sx={{ ":hover p": { color: "black" } }}
                  _hover={{ bgColor: `${configFile.secondaryAccentColor}`, color: "#FFF" }}
                  onClick={handleShowMore}
                >
                  {showMore ? "Show Less" : "Show More"}
                </Button>
              )}
            </Box>
          )}
          {sponsorLink && (
            <Box>
              <Text fontWeight="700" fontSize="18px">
                Our Sponsor
              </Text>
              <ChakraLink
                as={Link}
                href={sponsorLink}
                target="_blank"
                display="block"
                width="320px"
                height="248px"
                overflow="hidden"
                borderRadius="8px"
                mt="12px"
                position="relative"
                filter="drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))"
              >
                {sponsorImg && (
                  <Image
                    src={sponsorImg}
                    style={{ objectFit: "cover" }}
                    placeholder="blur"
                    blurDataURL={sponsorImg}
                    sizes="(max-width: 767px) 450px, 500px"
                    fill
                    alt="Ecto blog advertisment"
                  />
                )}
              </ChakraLink>
              <ChakraLink
                as={Link}
                href="/advertisement"
                fontSize="12px"
                display="block"
                textAlign="center"
                color="#718096"
                mt="8px"
                _hover={{ color: "#323232", textDecoration: "none" }}
              >
                ADVERTISE WITH US
              </ChakraLink>
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}

