import Image from "next/image";
import Link from "next/link";
import {
  Badge,
  Box,
  Flex,
  Text
} from "@chakra-ui/react";
import { BlogPostsMainData } from "@/Types/types";

import AuthorPostProfile from "@/components/AuthorPostProfile";

interface Props {
  hasImage?: boolean;
  hasCategory?: boolean;
  hasParagraph?: boolean;
  isAuthorSimplified?: boolean;
  isHorizontal?: boolean;
  isShortsStyle?: boolean;
  post: BlogPostsMainData;
}

export default function Post({
  hasImage = false,
  hasCategory = false,
  hasParagraph = false,
  isAuthorSimplified = false,
  isHorizontal = false,
  isShortsStyle = false,
  post,
}: Props) {

  return (
    <>
      {isShortsStyle ? (
        <Flex
          as={Link}
          href={`/blog/${post.attributes.url}`}
          flexDir={"column"}
          justifyContent={"flex-end"}
          padding={"32px"}
          bgSize="cover"
          bg={`linear-gradient(180deg, rgba(45, 55, 72, 0) 0%, #2D3748 88.02%), center / cover no-repeat url(${post.attributes.mainImage.data.attributes.url})`}
          boxShadow={
            "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
          }
          borderRadius={"8px"}
          flexShrink={{ base: "0", lg: "initial" }}
          maxW={{ base: "100%", lg: "auto" }}
          flex={"1 1 0"}
          _hover={{
            textDecoration: "none",
            boxShadow:
              "0px 2px 4px rgb(46 41 51 / 8%), 0px 5px 10px rgb(71 63 79 / 16%)",
            transform: "translateY(-0.25rem)",
          }}
        >

          <Text
            color={"white"}
            fontSize={"xl"}
            lineHeight={"7"}
            fontWeight={"bold"}
          >
            {post.attributes.title}
          </Text>

        </Flex>
      ) : (
        <Flex
          as={Link}
          href={`/blog/${post.attributes.url}`}
          boxSizing="border-box"
          flexDir={isHorizontal ? "row" : "column"}
          p={
            !hasImage && hasCategory && isAuthorSimplified && !hasParagraph
              ? "0px"
              : "8px"
          }
          gap={"8px"}
          border={"1px"}
          borderColor={"gray.100"}
          borderRadius={"8px"}
          bgColor={"white"}
          boxShadow={
            "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
          }
          height={isHorizontal ? "248px" : "530px"}
          justifyContent={isHorizontal ? "normal" : "space-between"}
          flex={"1 1 0"}
          _hover={{
            textDecoration: "none",
            boxShadow:
              "0px 2px 4px rgb(46 41 51 / 8%), 0px 5px 10px rgb(71 63 79 / 16%)",
            transform: "translateY(-0.25rem)",
          }}
        >
          {hasImage && (
            <Box maxW={isHorizontal ? '320px' : 'auto'}
              h={isHorizontal ? 'auto' : '220px'}
              borderRadius="4px" overflow="hidden">
              <Image
                src={post.attributes.mainImage.data.attributes.url}
                alt={
                  post.attributes.mainImage.data.attributes.alternativeText ||
                  ""
                }
                width={post.attributes.mainImage.data.attributes.width}
                height={post.attributes.mainImage.data.attributes.height}
                style={{
                  objectFit: 'cover',
                  height: "100%",
                }}
              />
            </Box>
          )}
          {/* content */}
          <Flex
            flexDir={"column"}
            p={
              !hasImage && hasCategory && isAuthorSimplified && !hasParagraph
                ? "20px"
                : "16px"
            }
            gap={
              hasCategory && hasParagraph && isAuthorSimplified
                ? "16px"
                : "24px"
            }
          >
            {/* heading n paragraph*/}
            <Flex flexDir={"column"} gap={"16px"} flex={"1 1 0"}>
              {/* category and title */}
              <Flex flexDir={"column"} gap={"8px"}>
                {hasCategory &&
                  <Flex gap={"12px"}>
                    {post.attributes.category.data.map((category: any, index: number) => (
                      <Badge
                        key={index}
                        bgColor={category.attributes.bgColor}
                        color={category.attributes.color}
                        w={"max-content"}
                      >
                        {category.attributes.title}
                      </Badge>
                    ))}
                  </Flex>
                }
                {!hasImage &&
                  hasCategory &&
                  isAuthorSimplified &&
                  !hasParagraph ? (
                  <Text
                    color={"gray.800"}
                    fontSize={"md"}
                    lineHeight={"6"}
                    fontWeight={"semibold"}
                  >
                    {post.attributes.title}
                  </Text>
                ) : (
                  <Text
                    color={"gray.800"}
                    fontSize={"xl"}
                    lineHeight={"7"}
                    fontWeight={"bold"}
                  >
                    {post.attributes.title}
                  </Text>
                )}
              </Flex>
              {hasParagraph && (
                <Text
                  color={"gray.600"}
                  fontSize={"md"}
                  lineHeight={"6"}
                  fontWeight={"normal"}
                >
                  {post.attributes.previewText}
                </Text>
              )}
            </Flex>
            <Flex justifyContent={"stretch"} justifySelf={"flex-end"}>
              {post.attributes.author && (
                <AuthorPostProfile
                  author={post.attributes.author}
                  simplified={isAuthorSimplified}
                  published={post.attributes.updatedAt}
                  readingTime={post.attributes.readingTime}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
}
