import { Stack, Text, Link as ChakraLink, Flex } from "@chakra-ui/react";
import Link from "next/link";
import Image from "next/image";
import { CategoryData } from "@/Types/types";
import { configFile } from "@/config";

interface Props {
  item: CategoryData;
}

function CategoryListItem({ item }: Props) {
  return (
    <ChakraLink
      as={Link}
      href={`/blog/categoria/${item.slug}`}
      borderRadius="8px"
      width="100%"
      display="flex"
      alignSelf="stretch"
      boxShadow="base"
      mt="12px"
      bgColor="#fff"
      sx={{ ":hover p": { color: "white" } }}
      _hover={{ bgColor: configFile.secondaryAccentColor, color: "white"}}
      position="relative"
    >
      <Flex
        width="72px"
        height="100%"
        background={`${configFile.primaryAccentColor}`}
        alignItems="center"
        justifyContent="center"
        position="absolute"
        left="0"
        top="0"
        borderLeftRadius="6px"
      >
        <Image
          width="24"
          height="24"
          src={item?.icon?.data?.attributes?.url}
          alt={item.title}
        />
      </Flex>
      <Stack
        paddingX="16px"
        paddingY="14px"
        direction="row"
        justify="flex-start"
        align="center"
        spacing="0px"
        overflow="hidden"
        ml="72px"
      >
        <Text
          lineHeight="1.5"
          fontWeight="semibold"
          fontSize="16px"
          color="gray.700"
        >
          {item.title}
        </Text>
      </Stack>
    </ChakraLink>
  );
}

export default CategoryListItem;
