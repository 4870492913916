import { Flex, Image as ChakraImage, Text } from "@chakra-ui/react";

import { TimeIcon } from "@chakra-ui/icons";
import { formatDate } from "@/helpers/formatDate";

interface Props {
  simplified?: boolean | undefined;
  author: any;
  published: string;
  readingTime: string;
}

export default function AuthorPostProfile({
  simplified = false,
  author,
  published,
  readingTime,
}: Props) {

  return (
    <Flex
      width={"100%"}
      alignItems={"center"}
      gap="16px"
      justifyContent={simplified ? "space-between" : ""}
    >
      {!simplified && (
        <ChakraImage
          boxSize="48px"
          w={"48px"}
          src={
            (author &&
                author?.data.attributes.img.data.attributes.url) ||
            "/images/dog.jpg"
          }
          objectFit="cover"
          borderRadius="100%"
          alt="User's profile image"
        />
      )}
      <Flex
        flexDir={simplified ? "row" : "column"}
        alignItems={simplified ? "center" : "flex-start"}
        gap="4px"
        flex={"1 1 0"}
        justifyContent={simplified ? "space-between" : "flex-start"}
      >
        {simplified ? (
          <>
            <Text
              fontWeight="medium"
              fontSize="sm"
              lineHeight="5"
              color="gray.600"
            >
              {author.data.attributes.name.split(" ").slice(0, 1)} •{" "}
              {formatDate(published)}
            </Text>
            <Flex
              fontWeight="normal"
              fontSize="sm"
              lineHeight="5"
              color="gray.600"
              alignItems={"center"}
              gap="4px"
            >
              <TimeIcon color={"gray.400"} /> {readingTime}{" "}
              min de leitura
            </Flex>
          </>
        ) : (
          <>
            <Text
              fontWeight="normal"
              fontSize="md"
              lineHeight="6"
              color="gray.800"
            >
              {author?.data.attributes.name || " "}
            </Text>
            <Text
              fontWeight="normal"
              fontSize="md"
              lineHeight="6"
              color="gray.600"
            >
              {formatDate(published)} • {readingTime}{" "}
              min de leitura
            </Text>
          </>
        )}
      </Flex>
    </Flex>
  );
}
